// extracted by mini-css-extract-plugin
export var background_images = "reviews-module__background_images___3Z1gR";
export var body_background = "#f8f9fa";
export var card = "reviews-module__card___RinVT";
export var chip = "reviews-module__chip___ofJX7";
export var container = "reviews-module__container___rUomY";
export var container_reviews = "reviews-module__container_reviews___iPe3n";
export var container_reviews__content_review = "reviews-module__container_reviews__content_review___FuIsh";
export var container_reviews__content_review__container_comments = "reviews-module__container_reviews__content_review__container_comments___QuEsq";
export var container_reviews__content_review__container_comments__card = "reviews-module__container_reviews__content_review__container_comments__card___XZqqq";
export var container_reviews__content_review__container_comments__card__quotes = "reviews-module__container_reviews__content_review__container_comments__card__quotes___IstHU";
export var container_reviews__content_review__container_comments__card__text = "reviews-module__container_reviews__content_review__container_comments__card__text___H8FnI";
export var container_reviews__content_review__container_comments__content_review = "reviews-module__container_reviews__content_review__container_comments__content_review___LJJd+";
export var container_reviews__content_review__content_btn = "reviews-module__container_reviews__content_review__content_btn___PbTta";
export var container_reviews__content_review__subtitle = "reviews-module__container_reviews__content_review__subtitle___bodiy";
export var container_reviews__content_review__text_btn = "reviews-module__container_reviews__content_review__text_btn___cM0S8";
export var container_reviews__content_world = "reviews-module__container_reviews__content_world___USdGh";
export var container_reviews__content_world__img = "reviews-module__container_reviews__content_world__img___ksYL3";
export var content = "reviews-module__content___OzVIJ";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "reviews-module__full_img___O10F7";
export var full_imgSmall = "reviews-module__full_img--small___G9mr-";
export var gray_21 = "reviews-module__gray_21___ExXDU";
export var image_container = "reviews-module__image_container___VBcpF";
export var image_item = "reviews-module__image_item___jdrxV";
export var lg = "1200px";
export var logo = "reviews-module__logo___GgCrO";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "reviews-module__primary___Q-+4e";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "reviews-module__title___2GJgF";
export var typography_h1 = "reviews-module__typography_h1___5wtvP";
export var typography_h2 = "reviews-module__typography_h2___HMDX0";
export var typography_h3 = "reviews-module__typography_h3___dp2cH";
export var white = "#fff";
export var white_button = "reviews-module__white_button___mzrH7";
export var xl = "1536px";
export var xxl = "2500px";