import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './history.module.scss';

const History = ({ intl: { formatMessage } }) => {
  return (
    <section className={styles.container_history}>
      <h3 className={styles.typography_h3}>
        {formatMessage({ id: 'aboutUsHistoryTitle' })}
      </h3>
      <h5 className={styles.container_history__subtitle}>
        {formatMessage({ id: 'aboutUsHistoryContent' })}
      </h5>
      <div className={styles.container_history__container_sections}>
        <div
          className={styles.container_history__container_sections__content_card}
        >
          <div
            className={
              styles.container_history__container_sections__content_card
            }
          >
            <div
              className={
                styles.container_history__container_sections__content_card__card_green
              }
            ></div>
            <div
              className={
                styles.container_history__container_sections__content_card__container_experience
              }
            >
              <div
                className={
                  styles.container_history__container_sections__content_card__container_experience__content_experience
                }
              >
                <StaticImage
                  src="../../../images/aboutUs/company.webp"
                  alt="company"
                  placeholder="blurred"
                  loading="lazy"
                  layout="constrained"
                  formats={['auto', 'webp', 'avif']}
                  className={
                    styles.container_history__container_sections__content_card__container_experience__content_experience__img
                  }
                />
              </div>
              <div
                className={
                  styles.container_history__container_sections__content_card__container_experience__content_text
                }
              >
                <p
                  className={
                    styles.container_history__container_sections__content_card__container_experience__text
                  }
                >
                  {formatMessage({ id: 'aboutUsHistoryYears' })}
                </p>
                <p
                  className={
                    styles.container_history__container_sections__content_card__container_experience__small
                  }
                >
                  {formatMessage({ id: 'aboutUsHistoryExperience' })}
                </p>
              </div>
            </div>
            <div className={styles.container_history__content_card_img}>
              <StaticImage
                src="../../../images/aboutUs/experience.webp"
                alt="experience"
                placeholder="blurred"
                loading="lazy"
                layout="constrained"
                formats={['auto', 'webp', 'avif']}
                className={styles.container_history__img_1}
              />
              <StaticImage
                src="../../../images/aboutUs/highlight_1.webp"
                alt="highlight_1"
                placeholder="blurred"
                loading="lazy"
                layout="constrained"
                formats={['auto', 'webp', 'avif']}
                className={styles.container_history__content_highlight_1}
              />
            </div>
          </div>
          <div
            className={
              styles.container_history__container_sections__content_card__section
            }
          >
            <h6
              className={
                styles.container_history__container_sections__content_card__section__card_title
              }
            >
              {formatMessage({ id: 'aboutUsHistory1Title' })}
            </h6>
            <p
              className={
                styles.container_history__container_sections__content_card__section__card_subtitle
              }
            >
              {formatMessage({ id: 'aboutUsHistory1Paragraph' })}
            </p>
          </div>
        </div>
        <div
          className={styles.container_history__container_sections__content_card}
        >
          <div
            className={
              styles.container_history__container_sections__content_card__section
            }
          >
            <h6
              className={
                styles.container_history__container_sections__content_card__section__card_title
              }
            >
              {formatMessage({ id: 'aboutUsHistory2Title' })}
            </h6>
            <p
              className={
                styles.container_history__container_sections__content_card__section__card_subtitle
              }
            >
              {formatMessage({ id: 'aboutUsHistory2Paragraph1' })}
            </p>
            <p
              className={
                styles.container_history__container_sections__content_card__section__card_subtitle
              }
            >
              {formatMessage({ id: 'aboutUsHistory2Paragraph2' })}
            </p>
          </div>
          <div
            className={
              styles.container_history__container_sections__content_card
            }
          >
            <div
              className={
                styles.container_history__container_sections__content_card__card_green_2
              }
            ></div>
            <div>
              <StaticImage
                src="../../../images/aboutUs/highlight_2.webp"
                alt="highlight_2"
                className={styles.container_history__content_highlight_2}
                placeholder="blurred"
                loading="lazy"
                layout="constrained"
                formats={['auto', 'webp', 'avif']}
              />
              <StaticImage
                src="../../../images/aboutUs/sector.webp"
                alt="sector"
                placeholder="blurred"
                loading="lazy"
                layout="constrained"
                formats={['auto', 'webp', 'avif']}
                className={styles.container_history__img_1}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

History.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(History);
